import * as yup from "yup";

const cpfRegex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/;
const cnpjRegex = /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/;

const validateCpfCnpj = (value) => {
  if (!value) return false;
  return cpfRegex.test(value) || cnpjRegex.test(value);
};

export default yup.object({
  numero_os: yup
    .string()
    .matches(/^[0-9]{7}-[0-9]{2}$/, "OS inválida")
    .required("Campo obrigatório"),
  cpf: yup
    .string()
    .test('validate-cpf-cnpj', 'CPF ou CNPJ inválido', validateCpfCnpj)
    .required("Campo obrigatório"),
});